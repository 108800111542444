<!-- 
	This is the tables page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

<template>
  <div>
    <a-tabs default-active-key="sms">
      <a-tab-pane key="sms" v-bind:tab="'SMS actions (' + tableSmsData.length + ')'">
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardActionTable
              :data="tableSmsData"
              :columns="table1Columns"
            ></CardActionTable>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="mail" v-bind:tab="'Email actions (' + tableEmailData.length + ')'">
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardActionTable
              :data="tableEmailData"
              :columns="table1Columns"
            ></CardActionTable>
          </a-col>
        </a-row>
      </a-tab-pane>
      <a-tab-pane key="webhook" v-bind:tab="'Webhook actions (' + tableWebData.length + ')'">
        <a-row :gutter="24" type="flex">
          <a-col :span="24" class="mb-24">
            <CardActionTable
              :data="tableWebData"
              :columns="table1Columns"
            ></CardActionTable>
          </a-col>
        </a-row>
      </a-tab-pane>
    </a-tabs>
    
  </div>
</template>

<script>
// "Products" table component.
import CardActionTable from "../components/Cards/CardActionTable";
import request from "../utils/request";
import { listaction } from "@/api/action";

// "Products" table list of columns and their properties.
const table1Columns = [
  {
    title: "Name",
    dataIndex: "name",
  },
  {
    title: "Message",
    dataIndex: "actionmessage",
  },
  {
    title: "Type",
    dataIndex: "actiontype",
  },
  {
    title: "Actiontaker",
    dataIndex: "actionto",
  },
  {
    title: "URL",
    dataIndex: "actionurl",
  },
  {
    title: "Goal",
    dataIndex: "goalname",
  },
  {
    title: "Edit",
    dataIndex: "actionid",
    scopedSlots: { customRender: "editBtn" },
    width: 50,
  },
  {
    title: "Delete",
    dataIndex: "actionid",
    key: "id2",
    scopedSlots: { customRender: "delBtn" },
    width: 50,
  },
];

// "Authors" table list of rows and their properties.
const table1Data = [];

export default {
  components: {
    CardActionTable,
  },
  methods: {

  },
  data() {
    return {
      // Associating "Products" table data with its corresponding property.
      table1Data: table1Data,
      tableSmsData: [],
      tableEmailData: [],
      tableWebData: [],

      // Associating "Products" table columns with its corresponding property.
      table1Columns: table1Columns,
    };
  },
  mounted() {
    // action-01
    listaction().then((data) => {
      this.table1Data = data;
      this.tableSmsData = [];
      this.tableEmailData = [];
      this.tableWebData = [];

      for (var i = 0; i < data.length; i++) {
        if (data[i]["actiontype"] == "sms") this.tableSmsData.push(data[i]);
        if (data[i]["actiontype"] == "mail") this.tableEmailData.push(data[i]);
        if (data[i]["actiontype"] == "webhook") this.tableWebData.push(data[i]);
      }
    });
  },
};
</script>

<style lang="scss">
</style>